import { Spinner, Helmet } from '@caminoai/ui';
import { Provider } from 'react-redux';
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Route, Switch, Router, Redirect } from 'react-router-dom';

import styles from './App.scss';
import { store } from './redux/store';
import history from './utils/history';
import ErrorBoundary from './components/ErrorBoundary';
import { useLocalStorage } from './hooks';
import { AppProvider, SearchParamProvider } from './context';
import { AUTHORIZATION_KEY, ORGANIZATION_ID_KEY } from './constants';
import ProtectedRoute from './pages/ProtectedRoute';
import { CaminoService } from './api/services';
import Logger from './Logger';

const Auth = lazy(() => import('./pages/Auth'));
const List = lazy(() => import('./pages/List'));
const Version = lazy(() => import('./pages/Version'));
const EditLayer = lazy(() => import('./pages/EditLayer'));
const ImportLayer = lazy(() => import('./pages/ImportLayer'));
const Notifications = lazy(() => import('./pages/Notifications'));
const Layout = lazy(() => import('./pages/Layout'));
const LayerDataList = lazy(() => import('./pages/LayerDataList'));

const App = () => {
  const [organizationId] = useLocalStorage(ORGANIZATION_ID_KEY, '');
  const [token] = useLocalStorage(AUTHORIZATION_KEY, '');
  const [organization, setOrganization] = useState(null);
  const [appReady, setAppReady] = useState(false);

  async function onFetchOrganization() {
    try {
      setAppReady(false);
      const org = await CaminoService.getOrganization();

      if (org) {
        setOrganization(org);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setAppReady(true);
    }
  }

  useEffect(() => {
    onFetchOrganization();
  }, []);

  return (
    <Provider store={store}>
      <Router history={history}>
        <AppProvider
          value={{
            organizationId,
            token,
            organization,
          }}
        >
          <SearchParamProvider>
            <ErrorBoundary>
              <Suspense
                fallback={
                  <div className={styles.spinner}>
                    <Spinner fullWidth />
                  </div>
                }
              >
                <Notifications />
                {appReady ? (
                  <>
                    <Helmet>
                      <title>
                        {organization
                          ? `${organization.name} | Clariti Launch Geo`
                          : 'Clariti Launch Geo'}
                      </title>
                    </Helmet>
                    <Switch>
                      <Route exact path="/auth-callback" component={Auth} />
                      <Route>
                        <Layout>
                          <Switch>
                            <ProtectedRoute exact path="/" component={List} />
                            <ProtectedRoute exact path="/new" component={EditLayer} />
                            <ProtectedRoute
                              exact
                              path="/:id/edit"
                              component={EditLayer}
                            />
                            <ProtectedRoute exact path="/:id" component={LayerDataList} />
                            <ProtectedRoute
                              exact
                              path="/:id/import"
                              component={ImportLayer}
                            />
                            <ProtectedRoute
                              exact
                              path="/:layerId/versions/:versionId"
                              component={Version}
                            />
                            {/* 404 */}
                            <ProtectedRoute
                              path="/*"
                              render={() => <Redirect to="/" />}
                            />
                          </Switch>
                        </Layout>
                      </Route>
                    </Switch>
                  </>
                ) : (
                  <div className={styles.spinner}>
                    <Spinner fullWidth />
                  </div>
                )}
              </Suspense>
            </ErrorBoundary>
          </SearchParamProvider>
        </AppProvider>
      </Router>
    </Provider>
  );
};

export default App;
