import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import PoweredBy from '../PoweredBy';

import styles from './styles.scss';

const Missing = ({ status, heading, description }) => (
  <span className={styles.Missing}>
    <div className={styles.Inner}>
      <h2 className={styles.Status}>{status}</h2>
      <h3 className={styles.Heading}>{heading}</h3>
      <div className={styles.Description}>{description}</div>
      <div className={styles.Footer}>
        <Icon className={styles.Icon} name="website" />
        Or visit us at
        <a className={styles.Link} href="https://www.claritisoftware.com/">
          claritisoftware.com
        </a>
      </div>
    </div>
    <PoweredBy />
  </span>
);

Missing.propTypes = {
  status: PropTypes.number,
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

Missing.defaultProps = {
  status: null,
  heading: null,
  description: null,
};

export default Missing;
