import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from '../Text/index';
import styles from './PoweredBy.scss';

const PoweredBy = ({ inverse, inverseLight, watermark, centered }) => (
  <div
    className={classnames(styles.Container, {
      [styles['Container--watermark']]: watermark,
      [styles['Container--centered']]: centered,
      [styles['Container--inverse']]: inverse,
      [styles['Container--inverse-light']]: inverseLight,
    })}
  >
    <Text className={styles.Text} size="xs">
      Powered by Clariti Launch
    </Text>
  </div>
);

PoweredBy.propTypes = {
  inverse: PropTypes.bool,
  inverseLight: PropTypes.bool,
  watermark: PropTypes.bool,
  centered: PropTypes.bool,
};

PoweredBy.defaultProps = {
  inverse: false,
  inverseLight: false,
  watermark: true,
  centered: false,
};

export default PoweredBy;
